// http请求相关
import axios from 'axios';
// import Cookies from 'js-cookie';
import store from '../store';
import router from '../router';
import {
	Loading
} from 'element-ui';

const http = axios.create({
	baseURL: process.env.VUE_APP_BASE_API + '/zhongtaiApi',
	// baseURL: 'http://192.168.0.88:8088',
	withCredentials: true,
	timeout: 15000,
	headers: {
		showLoading: true
	}
});

let loading = null;
let notShowError = false;
let responseMsg = '操作失败';
// 请求拦截
http.interceptors.request.use(
	config => {
		// 添加token
		// const token = Cookies.get('pp_token');
		const token = store.state.user.token;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		// config.headers['Content-Type'] = 'application/json;charset=utf-8';
		if (config.headers.showLoading) {
			if (router.history.current.path != '/login' && config.url != '/pc/home/getUserNoticeNumber') {
				loading = Loading.service({
					lock: true
				});
			}
			// loading = Loading.service({
			// 	lock: true
			// });
		}

		// console.log('js--------', config.headers.showLoading);

		if (config.headers.notShowError) {
			notShowError = true;
		}
		if (config.headers.responseMsg) {
			responseMsg = config.headers.responseMsg;
			console.log('responseMsg', responseMsg);
		}
		delete config.headers.showLoading;
		delete config.headers.responseMsg;
		delete config.headers.notShowError;

		return config;
	},
	error => {
		console.error(error);
		return Promise.reject(error);
	}
);

// 响应拦截
http.interceptors.response.use(
	response => {
		if (loading) {
		    loading.close();
			loading = null;
		}
		// if (response.status !== 200) {
		//     Message({
		//         message: response.Message || 'Error',
		//         type: 'error',
		//         duration: 5 * 1000,
		//         showClose: true
		//     });
		//     return Promise.reject(new Error(response.Message || 'Error'));
		// }
		const res = response.data;
		const flag = res.flag || (res.hasOwnProperty('code') && res.code === 200);
		if (!flag && !notShowError) {
			console.log('成功', res);
			if (res.code === 401) {
				var msg = store.state.user.token ? '登录信息过期，请重新登录' : '请先完成登录';
				sessionStorage.setItem('httpMsg', msg);
				store.dispatch('user/logout');
				router.push('/login');
			}
			return Promise.reject(res);
		} else {
			console.log('成功', res);
			return Promise.resolve(res);
		}
	},
	error => {
		if (loading) {
		    loading.close();
			loading = null;
		}
		let msg = error.message;
		if (msg && msg.search('Network Error') >= 0) {
			msg = '网络错误';
		}
		if (msg && msg.search('timeout') >= 0) {
			msg = '请求超时';
		} else {
			if (error.response && error.response.data && error.response.data.Message) {
				msg = error.response.data.Message;
			}
			if (error.response && error.response.status === 401) {
				msg = store.state.user.token ? '登录信息过期，请重新登录' : '请先完成登录';
				store.dispatch('user/logout');
				router.push('/passport');
			}
		}
		console.log('失败报错', error);
		// Message({
		//     message: msg,
		//     type: 'error',
		//     duration: 5 * 1000,
		//     showClose: true
		// });
		return Promise.reject(error);
	}
);

export default http;
