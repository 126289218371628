
export const global = {
	data() {
		return {
			selectEditeRole:{}
		}
	},
	
	watch: {
	},
	mounted() {
		
	},
	methods: {
		// 刷新
		Refresh: function() {
			window.location.reload();
		},
		// 返回
		GoBack() {
			this.$router.go(-1);
		},
		// 获取权限
		GetPower:function(pageRoad,name,type){
			
			var that = this;
			that.$api.http(
				'/pc/rolePower/getUserPower?type=7',
				'GET', {},
				function(res) {
					if(res.code==200)
					{
						localStorage.setItem('AuthorityList',JSON.stringify( res.data));
						if(res.data&&type)
						{
							that.GetAuthority(pageRoad,name,type);
						}
					}
					console.log('获取到的权限');
				},
				function(err) {
					console.log('错误', err);
				}
			);
		},
		// 获取权限 type 1目录 2页面或菜单 3按钮
		GetAuthority:function(pageRoad,name,type){
			var that=this;
			var authorityList=localStorage.getItem('AuthorityList');
			var isShow=false;
			if(authorityList)
			{
				authorityList=JSON.parse(authorityList);
				if(type==1)
				{
					authorityList.map(a=>{
						if(a.menuType=='M'&&a.name==name)
						{
							isShow=true;
						}
					})
				}
				else{
					var pageItem=that.GetChildPageAuthorith(authorityList,pageRoad);
					if(type==3)
					{
						if(pageItem)
						{
							pageItem.vos.map(a=>{
								if(a.name==name)
								{
									isShow=true;
								}
							})
						}
					}
					else{
						isShow= pageItem!=null;
					}
				}
			}
			else{
				this.GetPower(pageRoad,name,type);
			}
			// console.log('本地权限',authorityList)
			return isShow;
		},
		// 递归找到子集中指定的页面
		GetChildPageAuthorith:function(list,pageRoad)
		{
			var that=this;
			var item=null;
			list.map(a=>{
				if(a.component==pageRoad)
				{
					item= a;
				}
				else{
					if(a.vos&&a.vos.length>0)
					{
						a.vos.map(b=>{
							if(b.component==pageRoad)
							{
								item= b;
							}
						})
					}
				}
			})
			return item;
		},
		
		
		
	}
}