import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import store from '../store';

/**
 * 数字转填充0的字符串
 * @param {*} value
 */
const toDoubleString = (value) => {
	if (value < 10 && value >= 0) {
		return `0${value}`;
	}
	return value.toString();
};

/**
 * 验证手机号
 * @param {*} phone
 */
const validatePhone = (phone) => {
	if (!phone) {
		return false;
	}
	return /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/.test(phone);
};

// serverUrl
const serverUrl = function(type, page) {
	console.log("serverUrl", type, page);
	var str = "";
	// 1.智慧工程-pc
	if (type == 1) {
		str = process.env.VUE_APP_BASE_API + '/zhihuigongdi/#/' + page
	}
	// 3.资金管理
	if (type == 3) {
		str = process.env.VUE_APP_BASE_API + '/zijin/#/' + page
	}
	// 4.资产管理-pc
	if (type == 4) {
		str = process.env.VUE_APP_BASE_API + '/zichanjava/#/' + page
	}
	// 6.合同管理
	if (type == 6) {
		str = process.env.VUE_APP_BASE_API + '/hetong/#/' + page
	}
	// 8.人力资源
	if (type == 8) {
		str = process.env.VUE_APP_BASE_API + '/renli/#/' + page
	}
	// 9.合规管理
	if (type == 9) {
		str = process.env.VUE_APP_BASE_API + '/hegui/#/' + page
	}
	// 10.课程管理后台
	if (type == 10) {
		str = process.env.VUE_APP_BASE_API + '/kecheng/#/' + page
	}
	return str;
};

export default {
	/**
	 * 获取当前用户缓存
	 *
	 * @returns
	 */
	getUser() {
		const userStr = Cookies.get('pp_user');
		const key = Cookies.get('pp_provider');
		if (userStr && key) {
			const decodeStr = CryptoJS.AES.decrypt(userStr, key).toString(CryptoJS.enc.Utf8);
			if (decodeStr) {
				if (!store.state.user.user) {
					store.dispatch('user/setUser', JSON.parse(decodeStr));
				}
				return JSON.parse(decodeStr);
			}
		}
		return undefined;
	},
	/**
	 * 总秒数转 ‘hh:mm:dd’ 字符串
	 *
	 * @param {*} value
	 * @returns
	 */
	secondsToTime(value) {
		value = Number(value);
		const hour = Math.floor(value / 3600);
		const minute = Math.floor((value - hour * 3600) / 60);
		const second = Math.floor(value - hour * 3600 - minute * 60);
		return `${toDoubleString(hour)}:${toDoubleString(minute)}:${toDoubleString(second)}`;
	},
	validatePhone,
	serverUrl
};