import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const otherRoute = [];

const routeContext = require.context('./modules', true, /\.js$/);

routeContext.keys().forEach(item => {
	const routeModule = routeContext(item);
	if (Object.keys(routeModule).length > 0) {
		otherRoute.push(...(routeModule.default || routeModule));
	}
});

/**
 * 需要缓存的路由在meta中添加 keepAlive: true
 * 需要登录的页面在meta中添加 needLogin: true
 * 需要显示footer在meta中添加 showFooter: true
 */
const routes = [{
		path: '/',
		name: 'layout',
		component: () => import('../layout/index.vue'),
		children: [{
				path: '',
				redirect: '/login'
			}, {
				path: '/index',
				name: '首页',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/index.vue')
			},
			{
				path: '/home',
				name: '系统框架',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/home.vue'),
				children: [{
						path: '',
						redirect: '/aUser'
					},
					{
						path: '/aUser',
						name: '用户管理',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/aUser/index.vue')
					},
					{
						path: '/bQuanXian',
						name: '权限管理',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/bQuanXian/index.vue')
					},
					{
						path: '/bQuanXian_add',
						name: '新增角色',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/bQuanXian/add.vue')
					},
					{
						path: '/cZuZhi',
						name: '组织架构管理',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/cZuZhi/index.vue')
					},
					{
						path: '/dXiTong',
						name: '系统设置',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/dXiTong/index.vue')
					},
					{
						path: '/eLog',
						name: '操作日志',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/eLog/index.vue')
					},
					{
						path: '/jCompany',
						name: '公司单位管理',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/jCompany/index.vue')
					},
					{
						path: '/jCompany_details',
						name: '公司单位管理详情',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/jCompany/details.vue')
					},
					{
						path: '/kGovernment',
						name: '政府单位管理',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/kGovernment/index.vue')
					},
					{
						path: '/kGovernment_details',
						name: '政府单位管理详情',
						meta: {
							needLogin: true,
							showFooter: true
						},
						component: () => import('../views/kGovernment/details.vue')
					},
				]
			},
			{
				path: '/fList',
				name: '通知列表',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/fList/index.vue')
			},
			{
				path: '/gTongXun',
				name: '通讯簿',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/gTongXun/index.vue')
			},
			{
				path: '/hInfo',
				name: '公司信息',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/hInfo/index.vue')
			},
			{
				path: '/iEdit',
				name: '修改资料',
				meta: {
					needLogin: true,
					showFooter: true
				},
				component: () => import('../views/iEdit/index.vue')
			},
			// modules 其他路由
			...otherRoute
		]
	},
	{
		path: '/login',
		name: '登录',
		meta: {
			needLogin: true,
			showFooter: true
		},
		component: () => import('../views/login.vue')
	},
	// 错误路由
	{
		path: '/404',
		name: 'error',
		meta: {
			keepAlive: true
		},
		component: () => import('../layout/error.vue')
	},
	{
		path: '*',
		redirect: '/404'
	}
];

const router = new VueRouter({
	routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
	// if (to.meta.needLogin && !store.state.user.token && from.fullPath !== '/passport/login') {
	//     Message({
	//         message: '用户尚未登录，请登录',
	//         type: 'error',
	//         duration: 3 * 1000,
	//         showClose: true
	//     });
	//     next({
	//         path: '/passport/login'
	//     });
	// }
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	document.title = to.name;
	next();
});

export default router;
